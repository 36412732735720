import Section from "components/Section";
import Button from "components/Button";
import AuthorizedWalletsTable from "company/components/AuthorizedWalletsTable";
import AuthorizedNewWalletForm from "company/components/AuthorizedNewWalletForm";
import * as S from "./style";
import { useUser } from "context/User";
import { ItemCategoryType } from "types/common-enums";
import RefreshCounter, { CounterType } from "components/RefreshCounter";
import { useGetCompanyAgreements } from "company/hooks/useGetCompanyAgreements";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import FailedDataFetchingMessage from "components/FailedDataFetchingMessage";
import LoadingBox, { LoadingPlaceholderStyle } from "components/LoadingBox";

const AuthorizedWallets = () => {
    const { tokens, getTokensMetadataIsLoading, getTokensMetadataIsError } =
        useGetTokensMetadata();

    const { networks, getNetworksIsLoading, getNetworksIsError } =
        useGetNetworks();

    const {
        agreements,
        getCompanyAgreementsIsLoading,
        getCompanyAgreementsIsError,
        getCompanyAgreementsIsFetching,
        getCompanyAgreementsRefetch,
    } = useGetCompanyAgreements();

    const {
        items,
        getCompanyItemsDataUpdatedAt,
        getCompanyItemsIsError,
        getCompanyItemsIsLoading,
    } = useGetCompanyItems();

    const isLoading =
        getTokensMetadataIsLoading ||
        getNetworksIsLoading ||
        getCompanyItemsIsLoading ||
        getCompanyAgreementsIsLoading;

    const isError =
        getTokensMetadataIsError ||
        getNetworksIsError ||
        getCompanyItemsIsError ||
        getCompanyAgreementsIsError;

    const outboundPaymentItems = items.filter(
        (item) => item.type === ItemCategoryType.OutboundPayment && item.active
    );

    const { isReadOnly } = useUser();

    // This tables relies on agreements, items & config data
    if (isLoading) {
        return (
            <LoadingBox
                placeholderStyle={LoadingPlaceholderStyle.Table}
                tablePlaceholderCols={4}
            />
        );
    }

    if (isError) {
        return <FailedDataFetchingMessage />;
    }

    return (
        <>
            {!isReadOnly() && outboundPaymentItems.length !== 0 && (
                <Section>
                    <S.Filters>
                        <Button href="/pay">Pay</Button>
                        <AuthorizedNewWalletForm />
                    </S.Filters>
                </Section>
            )}
            <Section>
                <RefreshCounter
                    refreshedAt={getCompanyItemsDataUpdatedAt}
                    counter={CounterType.Running}
                    onRefresh={getCompanyAgreementsRefetch}
                    refreshing={getCompanyAgreementsIsFetching}
                />
                <AuthorizedWalletsTable
                    items={items}
                    tokens={tokens}
                    networks={networks}
                    agreements={agreements}
                />
            </Section>
        </>
    );
};

export default AuthorizedWallets;

import * as S from "./style";
import { HTMLAttributes, ReactNode } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Spacing } from "theme/spacing";
import { toDollar } from "utils/financial";
import { CustomerAuthorizationType } from "customer/types";
import useAuthorizations from "customer/hooks/useAuthorizations";
import Anchor from "components/Anchor";
import Paragraph from "components/Paragraph";
import SectionAndTitle from "customer/components/SectionAndTitle";
import Title from "components/Title";
import useGetCustomerData from "customer/hooks/useGetCustomerData";

interface MerchantProps extends HTMLAttributes<HTMLDivElement> {}

const Merchant = ({ ...props }: MerchantProps) => {
    const { entityId } = useParams();
    const { getAuthorizations } = useAuthorizations();

    const { data } = useGetCustomerData();

    const merchant = data?.entities?.find(
        ({ entityId: id }) => id === entityId
    );

    if (!merchant) {
        // [ ] Register error to dashboard
        // addNotification({
        //     msg: `Authorization not found`,
        //     type: NotificationType.ERROR,
        // });
        return <Navigate to="/" />;
    }

    const subscriptions = getAuthorizations({
        entityId,
        type: CustomerAuthorizationType.Subscription,
    });

    // [ ] PAID ONLY!!!
    const invoices = getAuthorizations({
        entityId,
        type: CustomerAuthorizationType.Invoice,
    }).reduce<
        {
            id: string;
            datePaid: string;
            itemName: string;
            amount: string;
            txHash: ReactNode;
            invoiceId: string;
        }[]
    >((allTxs, { transactions }) => {
        const invoiceTx = transactions.at(0);
        if (!invoiceTx) return allTxs;

        const { id, datePaid, amount, txHash, itemName, invoiceId } = invoiceTx;

        return [
            ...allTxs,
            {
                id,
                datePaid,
                itemName,
                amount,
                txHash,
                invoiceId,
            },
        ];
    }, []);

    return (
        <article {...props}>
            {merchant?.name && (
                <Title bold={false} spacing={[Spacing.sm, Spacing.xxl]}>
                    {merchant.name}
                </Title>
            )}
            {subscriptions.length > 0 && (
                <SectionAndTitle title="Subscriptions">
                    <S.SubTable>
                        {subscriptions.map(
                            ({ id, itemName, amount, statusBadge }) => (
                                <S.ListRow key={id}>
                                    <data>
                                        <Anchor href={`/${entityId}/${id}`}>
                                            {itemName}
                                        </Anchor>
                                    </data>
                                    <data>
                                        {toDollar((amount ?? 0).toString()) ??
                                            `Priced in token`}{" "}
                                    </data>
                                    <data>{statusBadge}</data>
                                </S.ListRow>
                            )
                        )}
                    </S.SubTable>
                </SectionAndTitle>
            )}
            <SectionAndTitle title="Invoices">
                {invoices.length ? (
                    <S.InvoiceTable>
                        {invoices.map(
                            ({ id, datePaid, amount, txHash, itemName }) => (
                                <S.ListRow key={id}>
                                    <data>{datePaid}</data>
                                    <data>{amount || `Priced in token`}</data>
                                    <data>{txHash}</data>
                                    <data>{itemName}</data>
                                </S.ListRow>
                            )
                        )}
                    </S.InvoiceTable>
                ) : (
                    <Paragraph>No paid invoices</Paragraph>
                )}
            </SectionAndTitle>
        </article>
    );
};

export default Merchant;

import { useEffect, useState } from "react";
import { CheckoutItemResponse } from "api/types/checkout";
import { BaseItem } from "types/common";
import {
    suggestedAllowanceAmountForCheckout,
    calculateSuggestedAllowance,
} from "utils/checkout";

export const useSuggestedAllowance = (
    invoiceAmount: number | undefined,
    items: CheckoutItemResponse[] | BaseItem[],
    minimumBalanceRequired: number | undefined,
    defaultSpendingCap: number | undefined,
    coupons: CouponResponse[] = []
) => {
    const [suggestedAllowance, setSuggestedAllowance] = useState<number>(0);

    let discountPercentage: number | undefined;
    let discountAmount: number | undefined;

    if (coupons.length > 0) {
        discountPercentage = coupons[0].discountPercentage || undefined;
        discountAmount = coupons[0].discountAmount || undefined;
    }

    useEffect(() => {
        setSuggestedAllowance(
            suggestedAllowanceAmountForCheckout(
                calculateSuggestedAllowance(
                    invoiceAmount,
                    items,
                    minimumBalanceRequired,
                    defaultSpendingCap,
                    { discountPercentage, discountAmount }
                )
            )
        );
    }, [
        invoiceAmount,
        items,
        minimumBalanceRequired,
        defaultSpendingCap,
        coupons,
        discountPercentage,
        discountAmount,
    ]);

    return suggestedAllowance;
};

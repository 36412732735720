import {
    Contract,
    formatUnits,
    parseUnits,
    TransactionReceipt,
    TransactionResponse,
} from "ethers";
import {
    createApproveCheckedInstruction,
    createAssociatedTokenAccountInstruction,
    createTransferInstruction,
    getAccount,
    getAssociatedTokenAddress,
    getMint,
} from "@solana/spl-token";
import {
    PublicKey,
    Transaction,
    TransactionSignature,
    SystemProgram,
    SignatureResult,
} from "@solana/web3.js";
import { NativeToken, nativeTokens, tokenABI } from "default-variables";
import { confirmTransactionWithRetries } from "utils/solana";
import {
    EvmProvider,
    EvmWalletSigner,
    SolProvider,
    SolWalletSigner,
} from "types/common";

export const getEvmTokenBalance = async ({
    provider,
    walletAddress,
    tokenAddress,
}: {
    provider: EvmProvider;
    walletAddress: string;
    tokenAddress?: string | null;
}): Promise<string> => {
    let decimals, balance;
    try {
        if (tokenAddress) {
            const erc20 = new Contract(tokenAddress, tokenABI, provider);

            decimals = await erc20.decimals();
            balance = await erc20.balanceOf(walletAddress);
        } else {
            // Native token
            balance = await provider.getBalance(walletAddress);
            decimals = 18;
        }
    } catch (error) {
        // [ ] This should probably throw, rather than log and leave the catch up to the caller
        console.error(`Failed to get EVM balance: ${error}`);
        return "0";
    }

    return formatUnits(balance, decimals);
};

export const getSolTokenBalance = async ({
    provider,
    walletAddress,
    tokenAddress,
}: {
    provider: SolProvider;
    walletAddress: string;
    tokenAddress?: string | null; // mint
}): Promise<string> => {
    let decimals, balance;
    try {
        if (tokenAddress) {
            const tokenAccount = await getAssociatedTokenAddress(
                new PublicKey(tokenAddress),
                new PublicKey(walletAddress)
            );

            const tokenAccountBalance = await provider.getTokenAccountBalance(
                tokenAccount
            );

            balance = Number(tokenAccountBalance.value.amount);
            decimals = tokenAccountBalance.value.decimals;
        } else {
            // Native token
            balance = await provider.getBalance(new PublicKey(walletAddress));
            decimals = 9;
        }
    } catch (error) {
        // [ ] This should probably throw, rather than log and leave the catch up to the caller
        console.error(`Failed to get Solana balance: ${error}`);
        return "0";
    }

    return formatUnits(balance, decimals);
};

export const getEvmTokenAllowance = async ({
    provider,
    walletAddress,
    contractAddress,
    tokenAddress,
}: {
    provider: EvmProvider;
    walletAddress: string;
    contractAddress: string;
    tokenAddress: string;
}): Promise<bigint> => {
    const erc20 = new Contract(tokenAddress, tokenABI, provider);
    return await erc20.allowance(walletAddress, contractAddress);
};

export const getSolTokenAllowance = async ({
    provider,
    walletAddress,
    contractAddress,
    tokenAddress,
}: {
    provider: SolProvider;
    walletAddress: string;
    contractAddress: string;
    tokenAddress: string; // mint
}): Promise<bigint> => {
    const tokenAccount = await getAssociatedTokenAddress(
        new PublicKey(tokenAddress),
        new PublicKey(walletAddress)
    );

    const { delegate, delegatedAmount } = await getAccount(
        provider,
        tokenAccount
    );

    if (delegate?.toString() !== contractAddress) {
        throw new Error("Delegate does not match contract address");
    }

    return BigInt(delegatedAmount.toString());
};

type SetEvmTokenAllowanceParams = {
    signer: EvmWalletSigner;
    contractAddress: string;
    tokenAddress: string;
    amount: bigint;
    awaitConfirm?: boolean;
};

type SetSolTokenAllowanceParams = {
    provider: SolProvider;
    signer: SolWalletSigner;
    walletAddress: string;
    contractAddress: string;
    tokenAddress: string; // mint
    amount: bigint;
    decimals: number;
    awaitConfirm?: boolean;
};

export const setEvmTokenAllowance = async ({
    signer,
    contractAddress,
    tokenAddress,
    amount,
    awaitConfirm,
}: SetEvmTokenAllowanceParams): Promise<bigint> => {
    const erc20 = new Contract(tokenAddress, tokenABI, signer);

    const allowanceTx = await erc20.approve(contractAddress, amount);

    if (awaitConfirm) {
        const receipt = await allowanceTx.wait();

        if (receipt.status === 0)
            return Promise.reject(
                `There was a problem registering your authorization increase`
            );
    }

    // [ ] Returning the receipt/signature may be useful

    // Note this simply returns the value passed in as the amount, but has not verified it through a requst
    return Promise.resolve(amount);
};

export const setSolTokenAllowance = async ({
    provider,
    signer,
    walletAddress,
    contractAddress,
    tokenAddress,
    amount,
    decimals,
    awaitConfirm,
}: SetSolTokenAllowanceParams): Promise<bigint> => {
    const tokenAccount = await getAssociatedTokenAddress(
        new PublicKey(tokenAddress),
        new PublicKey(walletAddress)
    );

    const transaction = new Transaction().add(
        createApproveCheckedInstruction(
            tokenAccount, // token account
            new PublicKey(tokenAddress), // mint
            new PublicKey(contractAddress), // delegate
            new PublicKey(walletAddress), // owner of token account
            amount,
            decimals
        )
    );

    const blockhash = await provider.getLatestBlockhash();
    transaction.recentBlockhash = blockhash.blockhash;
    transaction.feePayer = new PublicKey(walletAddress);

    const { signature }: { signature: TransactionSignature } =
        await signer.signAndSendTransaction(transaction);

    if (awaitConfirm) {
        try {
            await confirmTransactionWithRetries(
                provider,
                signature,
                "confirmed",
                [10000, 3000]
            );
        } catch (error) {
            // Confirm the allowance was correctly set
            const storedAllowance = await getSolTokenAllowance({
                provider,
                walletAddress,
                contractAddress,
                tokenAddress,
            });

            if (storedAllowance !== amount) {
                throw new Error(
                    `Your authorization (currently set to ${storedAllowance.toString()}) was not confirmed and may not be updated to ${amount.toString()}`
                );
            }
            console.warn(
                `Authorization was unable to verify the authorization using the transaction signature, but did verify the amount manually`
            );
        }
    }

    // [ ] Returning signature/receipt may be useful

    // Note this simply returns the value passed in as the amount, but has not verified it through a requst
    return Promise.resolve(amount);
};

export const sendEvmTokenPayment = async ({
    signer,
    tokenAddress,
    tokenAmount,
    toAddress,
    nativeToken = false,
}: {
    signer: EvmWalletSigner;
    tokenAddress: string;
    tokenAmount: string;
    toAddress: string;
    nativeToken?: boolean;
}): Promise<TransactionResponse> => {
    try {
        let tx: TransactionResponse;
        if (!nativeToken) {
            // ERC20 token transfer
            const erc20 = new Contract(tokenAddress, tokenABI, signer);
            const decimals = await erc20.decimals();
            const amount = parseUnits(tokenAmount, decimals);

            tx = await erc20.transfer(toAddress, amount);
        } else {
            // Native ETH transfer
            const amount = parseUnits(tokenAmount, 18);
            tx = await signer.sendTransaction({
                to: toAddress,
                value: amount,
            });
        }
        return tx;
    } catch (error) {
        console.error(`Failed to send EVM token payment: ${error}`);
        throw error;
    }
};

export const confirmEvmTransaction = async (
    tx: TransactionResponse
): Promise<TransactionReceipt> => {
    const receipt = await tx.wait();

    if (!receipt || receipt?.status === 0) {
        throw new Error(`Receipt status is 0`);
    }

    return receipt;
};

export const sendSolTokenPayment = async ({
    provider,
    signer,
    tokenAddress,
    tokenAmount,
    toAddress,
    nativeToken = false,
}: {
    provider: SolProvider;
    signer: SolWalletSigner;
    tokenAddress: string;
    tokenAmount: string;
    toAddress: string;
    nativeToken?: boolean;
}): Promise<TransactionSignature> => {
    try {
        if (!signer.publicKey) {
            throw new Error(`No public key found for wallet`);
        }

        const fromPubkey = new PublicKey(signer.publicKey);
        const toPubkey = new PublicKey(toAddress);
        const transaction = new Transaction();

        if (!nativeToken) {
            // SPL Token transfer
            const mint = new PublicKey(tokenAddress);
            const fromTokenAccount = await getAssociatedTokenAddress(
                mint,
                fromPubkey
            );
            const toTokenAccount = await getAssociatedTokenAddress(
                mint,
                toPubkey
            );

            const tokenAccountInfo = await provider.getAccountInfo(
                toTokenAccount
            );
            if (!tokenAccountInfo) {
                transaction.add(
                    createAssociatedTokenAccountInstruction(
                        fromPubkey,
                        toTokenAccount,
                        toPubkey,
                        mint
                    )
                );
            }

            const mintInfo = await getMint(provider, mint);
            const decimals = mintInfo.decimals;
            const amount = parseUnits(tokenAmount, decimals);

            transaction.add(
                createTransferInstruction(
                    fromTokenAccount,
                    toTokenAccount,
                    fromPubkey,
                    amount
                )
            );
        } else {
            // Native SOL transfer
            const amount = parseUnits(tokenAmount, 9);
            transaction.add(
                SystemProgram.transfer({
                    fromPubkey,
                    toPubkey,
                    lamports: amount,
                })
            );
        }

        const { blockhash } = await provider.getLatestBlockhash();
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = fromPubkey;

        const { signature } = await signer.signAndSendTransaction(transaction);

        return signature;
    } catch (error) {
        console.error(`Failed to send Solana token payment: ${error}`);
        throw error;
    }
};

export const confirmSolTransaction = async (
    provider: SolProvider,
    signature: TransactionSignature
): Promise<SignatureResult> => {
    return await confirmTransactionWithRetries(
        provider,
        signature,
        "finalized", // If we don't wait for "finalized" here, hash links will be dead (tx won't be on Solscan yet)
        [20000, 20000, 20000]
    );
};

export const getNativeTokenBySymbol = (
    searchSymbol: string
): NativeToken | undefined =>
    nativeTokens.find(
        ({ symbol }) => symbol.toUpperCase() === searchSymbol.toUpperCase()
    );

export const getNativeTokenByNetworkId = (
    searchNetwork: number
): NativeToken | undefined =>
    nativeTokens.find(({ networks }) =>
        networks.find(({ networkId }) => networkId === searchNetwork)
    );

export const getWrapperSymbols = (): string[] =>
    nativeTokens
        .reduce<string[]>((uniques, { networks }) => {
            return [
                ...uniques,
                ...networks.map(({ wrapper }) => wrapper.toUpperCase()),
            ];
        }, [])
        .filter((symbol, index, arr) => arr.indexOf(symbol) === index);

// [ ] As soon as this is used for something meaningful, we should move this logic to the db
export const isStableCoin = ({
    networkId,
    address,
}:
    | {
          networkId: number;
          address: string;
      }
    | BaseToken): boolean =>
    !![
        { networkId: 1, address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48" },
        {
            networkId: 10,
            address: "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
        },
        {
            networkId: 10,
            address: "0x94b008aa00579c1307b0ef2c499ad98a8ce58e58",
        },
        {
            networkId: 10,
            address: "0x0b2c639c533813f4aa9d7837caf62653d097ff85",
        },
        {
            networkId: 56,
            address: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
        },
        {
            networkId: 56,
            address: "0x55d398326f99059ff775485246999027b3197955",
        },
        {
            networkId: 137,
            address: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
        },
        {
            networkId: 137,
            address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
        },
        {
            networkId: 137,
            address: "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
        },
        {
            networkId: 900,
            address: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
        },
        {
            networkId: 8453,
            address: "0x833589fcd6edb6e08f4c7c32d4f71b54bda02913",
        },
        {
            networkId: 42161,
            address: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
        },
        {
            networkId: 42161,
            address: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
        },
        {
            networkId: 42161,
            address: "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
        },
        {
            networkId: 11155111,
            address: "0x1c7d4b196cb0c7b01d743fbc6116a902379c7238",
        },
        {
            networkId: 11155111,
            address: "0x94a9d9ac8a22534e3faca9f4e7f2e2cf85d5e4c8",
        },
        {
            networkId: 901,
            address: "4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU",
        },
    ].find(({ networkId: n, address: a }) => networkId === n && address === a);

import styled from "styled-components";
import { sizes as s } from "global-style";
import spacing from "theme/spacing";
import { itemSpacing } from "customer/App/style";

export const SubTable = styled.ul`
    display: grid;
    gap: ${spacing[itemSpacing]};
    grid-template-columns: 1fr;
    @media (min-width: ${s.sm}) {
        grid-template-columns: minmax(12rem, auto) auto 1fr;
    }
`;

export const InvoiceTable = styled.ul`
    display: grid;
    gap: ${spacing[itemSpacing]};
    grid-template-columns: 1fr;
    @media (min-width: ${s.sm}) {
        grid-template-columns:
            minmax(clamp(8rem, 20%, 10rem), auto) minmax(
                clamp(16rem, 35%, 20rem),
                auto
            )
            minmax(15%, auto)
            1fr;
    }
`;

export const ListRow = styled.li`
    display: grid;
    column-gap: 1rem;
    align-items: center;
    grid-column: 1 / -1;
    grid-template-columns: inherit;
    @supports (grid-template-columns: subgrid) {
        grid-template-columns: subgrid;
    }
`;

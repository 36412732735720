import { HTMLAttributes } from "react";
import { Spacing } from "theme/spacing";
import Anchor from "components/Anchor";
import ErrorMessage from "components/ErrorMessage";
import Section from "components/Section";
import { useCustomer } from "customer/context/Customer";
import { Navigate } from "react-router-dom";

interface HomeProps extends HTMLAttributes<HTMLDivElement> {}

const Home = ({ ...props }: HomeProps) => {
    const { merchantMenuItems, isLoading } = useCustomer();

    if (isLoading) return <></>;

    if (merchantMenuItems.length !== 0)
        return <Navigate to={merchantMenuItems[0].path} />;

    return (
        <Section spacing={[Spacing.lg]} {...props}>
            <ErrorMessage msg="No authorizations to display yet">
                <p>
                    Need help? Take a look at our{" "}
                    <Anchor href={import.meta.env.VITE_LOOP_DOCS_FAQ}>
                        documentation
                    </Anchor>{" "}
                    or{" "}
                    <Anchor href={`mailto:${import.meta.env.VITE_EMAIL_HELP}`}>
                        contact us
                    </Anchor>
                    .
                </p>
            </ErrorMessage>
        </Section>
    );
};

export default Home;

import { useState, useEffect } from "react";
import { CustomerAuthorization } from "customer/types";
import { useWalletConnected } from "context/Wallet/WalletConnected";
import { tokenToCents } from "utils/exchangeRates";

const useTokenBalance = (authorization: CustomerAuthorization | undefined) => {
    const [balance, setBalance] = useState<{
        balanceCoin: string | null;
        balanceUsd: string | null;
    }>({ balanceCoin: null, balanceUsd: null });
    const { getTokenBalance } = useWalletConnected();

    useEffect(() => {
        const fetchBalance = async () => {
            if (!authorization || !authorization.token) {
                setBalance({ balanceCoin: null, balanceUsd: null });
                return;
            }

            const balanceCoin = await getTokenBalance({
                tokenAddress: authorization.tokenAddress,
                walletAddress: authorization.wallet,
                networkId: authorization.networkHex,
                force: true,
            });

            const balanceUsd = tokenToCents(
                balanceCoin,
                authorization.token.exchange.rate || 0
            );
            setBalance({ balanceCoin, balanceUsd });
        };

        fetchBalance();
    }, [authorization, authorization?.token, getTokenBalance]);

    return balance;
};

export default useTokenBalance;

import * as S from "./style";
import { apiServerUrl } from "default-variables";
import {
    RefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { formatUnits } from "ethers";
import { firstToUpper } from "utils/strings";
import { undecimalizeNumber } from "utils/financial";
import { validateInput } from "utils/formValidation";
import { toNumber } from "utils/numbers";
import { Company, CompanyAgreementResponse } from "company/types";
import { SmartContractType } from "company/routes/Developer";
import { useNotificationQueue } from "context/NotificationQueue";
import { useValidateInvoiceForm } from "company/hooks/useValidateInvoiceForm";
import { usePanelSummaryAsInvoiceFormStatus } from "company/hooks/usePanelSummaryAsInvoiceFormStatus";
import Details from "components/Details";
import Anchor from "components/Anchor";
import { TagsInputRef } from "company/components/TagsInput";
import ErrorMessage from "components/ErrorMessage";
import WalletConnect from "components/WalletConnect";
import AmountInUsd from "./AmountInUsd";
import InvoiceAmount from "./InvoiceAmount";
import ReceivingWallet from "./ReceivingWallet";
import AgreementToInvoice from "./AgreementToInvoice";
import AgreementItems from "./AgreementItems";
import { NotificationType } from "components/Notification";
import {
    CommonBlockchainNetworkResponse,
    GeneralTokenDetailsResponse,
} from "api";
import { useGetCompanyAgreements } from "company/hooks/useGetCompanyAgreements";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import { useGetCompanyTransfers } from "company/hooks/useGetCompanyTransfers";
import { useEns } from "context/EnsProvider";
import { useUser } from "context/User";
import { isPricedInToken } from "utils/items";
import { useWallet } from "context/Wallet";

// This is defined by the backend (transfer-models.ts)
interface NewTransfer {
    signature?: string;
    invoiceId: string;
    entityId: string;
    amount: string;
    usd: boolean;
    to: string;
    from: string;
    itemId: string;
    token: string;
    billDate?: number;
    networkId: number;
    tagIds?: number[];
    notes?: string;
}

enum Panels {
    ITEM = "ITEM",
    AGREEMENT = "AGREEMENT",
    SCHEDULE = "DETAILS",
}

interface InvoiceFormProps {
    agreement?: CompanyAgreementResponse;
}

const getInboundTreasuryFromAgreement = (
    entities: Company.Entity[],
    agreement: CompanyAgreementResponse | undefined
) =>
    entities.find(({ entityId: eId }) => eId === agreement?.entity)
        ?.inboundTreasuries?.[agreement?.networkId?.toString() || ``]?.[0] ||
    agreement?.receiver?.wallet ||
    ``;

const InvoiceForm = ({ agreement: defaultAgreement }: InvoiceFormProps) => {
    //              ------- HOOKS -------
    const { addNotification, removeNotification } = useNotificationQueue();
    const {
        generateTransferSignatureToken,
        walletConnected,
        networkConnected,
    } = useWallet();
    const { getEntityId, getSessionToken } = useUser();
    const navigate = useNavigate();

    const { getEnsRecord } = useEns();

    const { invalidateAllTransfersQueries } = useGetCompanyTransfers();
    const { tokens } = useGetTokensMetadata();
    const {
        config: { contracts, entities },
    } = useGetCompanyConfig();
    const { networks } = useGetNetworks();
    const { items } = useGetCompanyItems();

    const { agreements, getCompanyAgreementsRefetch } =
        useGetCompanyAgreements();

    //              ------- INPUT STATE VARIABLES -------
    // If agreement is set in the URL, default agreementId to that
    const [agreement, setAgreement] =
        useState<CompanyAgreementResponse | undefined>(defaultAgreement);
    // If agreement is set in the URL, default itemId to the first item in the agreement
    const [item, setItem] = useState<Company.Item | undefined>(
        items.find(({ id }) => id === defaultAgreement?.items[0])
    );
    const [toWallet, setToWallet] = useState<string>(
        getInboundTreasuryFromAgreement(entities, defaultAgreement)
    );
    const [token, setToken] = useState<GeneralTokenDetailsResponse | undefined>(
        tokens.find(
            ({ address, networkId }) =>
                address === defaultAgreement?.token &&
                networkId === defaultAgreement?.networkId
        )
    );
    const [network, setNetwork] = useState<
        CommonBlockchainNetworkResponse | undefined
    >(networks.find(({ id }) => id === defaultAgreement?.networkId));
    const [amount, setAmount] = useState<string>(
        item?.amount && item.amount !== 0
            ? toNumber(formatUnits(item.amount, 2)).toFixed(2)
            : ``
    );
    const [usd, setUsd] = useState<boolean>(
        !isPricedInToken(item?.amount ?? null)
    );
    const [tags, setTags] = useState<number[]>([]);
    const [notes, setNotes] = useState<string>(``);
    const [billDates, setBillDates] = useState<Date[]>([new Date()]);
    const [invoiceIds, setInvoiceIds] = useState<string[]>([``]);
    const [contract, setContract] = useState<Company.Contract | undefined>(
        contracts.find(
            (c: Company.Contract) =>
                c.contractType === SmartContractType.VariableRate &&
                c.networkId === defaultAgreement?.networkId
        )
    );

    //              ------- COMPONENT VARIABLES -------
    const [isFormWorking, setIsFormWorking] = useState<boolean>(false);
    const [isAllSigned, setIsAllSigned] = useState<boolean>(false);
    const [isCertifiedSigner, setIsCertifiedSigner] = useState<boolean>(false);
    const { delegatedSigning } = useMemo(
        () =>
            entities.find(({ entityId: eId }) => eId === getEntityId()) ||
            ({
                delegatedSigning: false,
            } as Partial<Company.Entity>),
        [entities, getEntityId]
    );

    //              ------- FORM VALIDATION REFS -------
    // @kirkas, the only reason for the refs in this component is just validate the inputs
    // But since we have the inputs, can we modify the validation functions to both accept
    // the component ref, OR the value and validate the same way?
    const amountRef = useRef<HTMLInputElement>(null);
    const toAddressRef = useRef<HTMLInputElement>(null);
    const tagsInputRef = useRef<TagsInputRef>(null);
    const invoiceIdsRef = useRef<RefObject<HTMLInputElement>[]>([]);
    const billDatesRef = useRef<RefObject<HTMLInputElement>[]>([]);
    const billDetailsRef = useRef({
        invoiceIdsRef,
        billDatesRef,
    });

    const { isFormValid, valid } = useValidateInvoiceForm({
        item,
        agreement,
        token,
        network,
        amountRef,
        toAddressRef,
        invoiceIdsRef,
        billDatesRef,
    });

    //              ------- PANEL MANAGEMENT -------
    const { panelsOpen, setPanelOpen, setPanelDisabled } = usePanels({
        [Panels.ITEM]: { open: !item, disabled: false },
        [Panels.AGREEMENT]: { open: !agreement, disabled: !!item },
        [Panels.SCHEDULE]: { open: !!agreement, disabled: !!agreement },
    });

    const { summaryItem, summaryAgreement, summarySchedule } =
        usePanelSummaryAsInvoiceFormStatus({
            Panels,
            panelsOpen,
            agreement,
            item,
            agreements,
            network,
            token,
            valid,
            isFormWorking,
        });

    //              ------- INCOMING PROPS CHANGED -------
    useEffect(() => {
        if (!defaultAgreement) return;
        setAgreement(defaultAgreement);
        setItem(items.find(({ id }) => id === defaultAgreement?.items[0]));
    }, [defaultAgreement, items]);

    //              ------- ITEM CHANGED -------
    useEffect(() => {
        if (!items || items.length < 1) return;

        // Enable/disable 2nd panel based on item selected
        setPanelDisabled(Panels.AGREEMENT, !item);

        if (!item) {
            setPanelOpen(Panels.AGREEMENT, false);
            setAgreement(undefined);
            return;
        }

        // New item selected does belong to current agreement, clear agreement
        if (agreement?.items && !agreement?.items.includes(item?.id)) {
            setAgreement(undefined);
        }

        // Manage the panel states
        setPanelOpen(Panels.ITEM, false);
        setPanelOpen(Panels.AGREEMENT, true);

        // Update field defaults
        setAmount(
            item?.amount && item.amount !== 0
                ? toNumber(formatUnits(item.amount, 2)).toFixed(2)
                : ``
        );
        setUsd(!isPricedInToken(item?.amount ?? null));

        // Update validity
        validateInput({ input: amountRef.current!, valid: true });
    }, [item, items, agreement?.items, setPanelOpen, setPanelDisabled]);

    //              ------- AGREEMENT CHANGED -------
    useEffect(() => {
        if (!agreements || agreements.length < 1) return;

        // Enable/disable 3rd panel based on agreement status
        setPanelDisabled(Panels.SCHEDULE, !agreement);

        if (!agreement) {
            navigate(`/invoice`);
            return;
        }

        // Manage the URL param and panel states
        // setSearchParams({ agreementId: agreement?.id });
        navigate(`/invoice/${agreement?.id}`);
        setPanelOpen(Panels.AGREEMENT, false);
        setPanelOpen(Panels.SCHEDULE, true);

        // Update field defaults
        setToken(
            tokens.find(
                ({ address, networkId }) =>
                    address === agreement.token &&
                    networkId === agreement.networkId
            )
        );
        setNetwork(networks.find(({ id }) => id === agreement.networkId));
        setToWallet(getInboundTreasuryFromAgreement(entities, agreement) || ``);
        setContract(
            contracts.find(
                (c: Company.Contract) =>
                    c.contractType === SmartContractType.VariableRate &&
                    c.networkId === agreement.networkId
            )
        );

        // Update validity
        validateInput({ input: toAddressRef.current!, valid: true });
    }, [
        agreement,
        agreements,
        tokens,
        networks,
        contracts,
        entities,
        setPanelOpen,
        setPanelDisabled,
        navigate,
    ]);

    //              ------- WALLET CHANGED -------
    useEffect(() => {
        setIsCertifiedSigner(
            !!walletConnected &&
                walletConnected?.address.toLowerCase() ===
                    contract?.certifiedSigner?.toLowerCase() &&
                networks.find(({ id }) => id === contract?.networkId)?.hexId ===
                    networkConnected?.networkId
        );
    }, [walletConnected, networkConnected, contract, networks]);

    //              ------- SUBMIT HANDLER -------
    const handleSubmit = useCallback(async () => {
        if (!delegatedSigning && !isCertifiedSigner) {
            addNotification({
                msg: (
                    <>
                        Signer wallet{" "}
                        {contract?.certifiedSigner ? (
                            <S.CertifiedSigner
                                address={contract.certifiedSigner}
                                ensName={
                                    getEnsRecord(contract.certifiedSigner)?.name
                                }
                                networkId={network?.hexId}
                                inheritColor={false}
                                shorten
                                icon
                            />
                        ) : (
                            ``
                        )}
                        <br />
                        must be connected to submit this invoice.
                    </>
                ),
                type: NotificationType.ERROR,
            });
            return Promise.reject(
                `Signer wallet must be connected to create an invoice`
            );
        }
        if (!isFormValid()) {
            addNotification({
                msg: (
                    <>
                        Something unexpected happened while validating this
                        invoice form.
                        <br />
                        <Anchor
                            href={`mailto:${
                                import.meta.env.VITE_EMAIL_SUPPORT
                            }`}
                        >
                            Contact support
                        </Anchor>{" "}
                        for assistance.
                    </>
                ),
                type: NotificationType.ERROR,
            });
            return Promise.reject(`Invoice form is not valid`);
        }

        setIsFormWorking(true);

        const signingNotification =
            !delegatedSigning &&
            addNotification({
                msg: (
                    <>
                        Preparing to schedule {billDates.length} payment
                        {billDates.length === 1 ? `` : `s`}.<br />
                        Check your wallet for signing instructions.
                    </>
                ),
                type: NotificationType.WORKING,
                expires: false,
            });

        const newTransfers = await billDates
            .reduce(async (promise, txDate: Date, index: number): Promise<
                NewTransfer[]
            > => {
                const transfers = await promise;

                // Past date bills set `0`, which the backend translates to `Date.now()`
                const billDate =
                    txDate.valueOf() < Date.now() ? 0 : txDate.valueOf() / 1000;

                // Convert to cents if USD, otherwise convert with token decimals
                const decimals = usd ? 2 : token?.decimals;
                if (!decimals) {
                    addNotification({
                        msg: (
                            <>
                                Something unexpected happened while setting up
                                the transfer.
                                <br />
                                <Anchor
                                    href={`mailto:${
                                        import.meta.env.VITE_EMAIL_SUPPORT
                                    }`}
                                >
                                    Contact support
                                </Anchor>{" "}
                                for assistance.
                            </>
                        ),
                        type: NotificationType.ERROR,
                    });
                    return Promise.reject(`Token data was invalid`);
                }

                // Use ethers.utils to get the correct numbers for API
                const amountForApi = undecimalizeNumber(amount, decimals);

                // Sign the transfer individually if entity is not a delegated signer
                const signature: string = !delegatedSigning
                    ? await generateTransferSignatureToken({
                          contractAddress: contract?.address,
                          contractNetworkId: contract?.networkId,
                          invoiceId: invoiceIds[index],
                          toAddress: toWallet,
                          fromAddress: agreement?.sender?.wallet,
                          token: token?.address,
                          amount: amountForApi,
                          usd: usd,
                      }).catch((error: any) => {
                          addNotification({
                              msg: (
                                  <>
                                      Something unexpected happened while
                                      generating a transfer signature.
                                      <br />
                                      <Anchor
                                          href={`mailto:${
                                              import.meta.env.VITE_EMAIL_SUPPORT
                                          }`}
                                      >
                                          Contact support
                                      </Anchor>{" "}
                                      for assistance.
                                  </>
                              ),
                              type: NotificationType.ERROR,
                          });
                          return Promise.reject(error);
                      })
                    : ``;

                // Some values are given non-null assertion, as they're validated in `isFormValid()`
                return [
                    ...transfers,
                    {
                        signature,
                        invoiceId: invoiceIds[index],
                        entityId: getEntityId(),
                        amount: amountForApi,
                        usd,
                        to: toWallet,
                        from: agreement?.sender?.wallet,
                        itemId: item?.id,
                        token: token?.address,
                        billDate,
                        networkId: network?.id,
                        tagIds: tags,
                        notes,
                    } as NewTransfer,
                ];
            }, Promise.resolve([] as NewTransfer[]))
            .catch((error) => {
                setIsFormWorking(false);
                addNotification({
                    msg: (
                        <>
                            Not all payment requests were signed as expected.
                            Please try again.
                            <br />
                            Contact{" "}
                            <Anchor
                                href={`mailto:${
                                    import.meta.env.VITE_EMAIL_SUPPORT
                                }`}
                            >
                                {import.meta.env.VITE_EMAIL_SUPPORT}
                            </Anchor>{" "}
                            if you continue to experience issues.
                        </>
                    ),
                    type: NotificationType.ERROR,
                });
                return Promise.reject(error);
            })
            .finally(() => {
                if (signingNotification)
                    removeNotification(signingNotification);
            });

        setIsAllSigned(true);

        const schedulingNotification = addNotification({
            msg: (
                <>
                    Scheduling {newTransfers.length} new payment
                    {billDates.length === 1 ? `` : `s`}.
                </>
            ),
            type: NotificationType.WORKING,
            expires: false,
        });

        await fetch(`${apiServerUrl}/api/v1/company/transfers`, {
            method: `POST`,
            headers: {
                Authorization: getSessionToken(),
                "Content-Type": "application/json",
                "entity-id": getEntityId(),
            },
            body: JSON.stringify(newTransfers),
        })
            .then(async (response) => {
                const result = await response.json();

                if (!response.ok || response.status !== 200) {
                    if (result.code === 400 && result.results) {
                        const [, invoiceId, msg] = result.results.match(
                            /Error: \[Id (\w+)\]: (.+?)\./
                        );
                        throw new Error(`${firstToUpper(msg)} (${invoiceId}).`);
                    } else {
                        throw new Error(
                            `Your payments were not scheduled, something went wrong.`
                        );
                    }
                }

                removeNotification(schedulingNotification);
                addNotification({
                    msg: (
                        <>
                            {result.transfers.length} payment
                            {result.transfers.length === 1 ? `` : `s`}{" "}
                            successfully scheduled.
                        </>
                    ),
                    type: NotificationType.SUCCESS,
                });

                invalidateAllTransfersQueries();
                await getCompanyAgreementsRefetch();

                if (
                    result.transfers.filter(
                        ({ billDate }: { billDate: number }) =>
                            billDate >= Date.now() / 1000
                    ).length > 0
                )
                    navigate(`/transactions/upcoming`);
                else navigate(`/transactions/uncollectible`);
            })
            .catch((error) => {
                removeNotification(schedulingNotification);
                addNotification({
                    msg: (
                        <>
                            {error.message}
                            <br />
                            Contact{" "}
                            <Anchor
                                href={`mailto:${
                                    import.meta.env.VITE_EMAIL_SUPPORT
                                }`}
                            >
                                {import.meta.env.VITE_EMAIL_SUPPORT}
                            </Anchor>{" "}
                            if you continue to experience issues.
                        </>
                    ),
                    type: NotificationType.ERROR,
                });
                return Promise.reject(`Your payments were not scheduled`);
            })
            .finally(() => {
                setIsFormWorking(false);
                setIsAllSigned(false);
            });
    }, [
        isFormValid,
        getEntityId,
        amount,
        usd,
        toWallet,
        tags,
        notes,
        billDates,
        invoiceIds,
        item,
        token,
        agreement,
        network,
        contract,
        addNotification,
        removeNotification,
        generateTransferSignatureToken,
        delegatedSigning,
        isCertifiedSigner,
        getSessionToken,
        navigate,
    ]);

    return items.length < 1 ? (
        <ErrorMessage msg="No items to invoice">
            <AddNewItems />
        </ErrorMessage>
    ) : (
        <>
            {/* 1. SELECT ITEM */}
            <Details
                summary={summaryItem}
                open={panelsOpen[Panels.ITEM].open}
                disabled={panelsOpen[Panels.ITEM].disabled}
                onToggle={(event: any) =>
                    setPanelOpen(Panels.ITEM, event.target.open)
                }
            >
                <S.DetailsIntro>
                    <AddNewItems />
                </S.DetailsIntro>
                <AgreementItems
                    items={items}
                    agreements={agreements}
                    entities={entities}
                    value={item?.id}
                    setValue={setItem}
                    disabled={isFormWorking}
                />
            </Details>

            {/* 2. CHOOSE AGREEMENT */}
            <Details
                summary={summaryAgreement}
                open={panelsOpen[Panels.AGREEMENT].open}
                disabled={panelsOpen[Panels.AGREEMENT].disabled}
                onToggle={(event: any) =>
                    setPanelOpen(Panels.AGREEMENT, event.target.open)
                }
            >
                {item && (
                    <S.DetailsIntro>
                        <p>
                            Select from a list of the existing customer
                            agreements for
                            {` `}
                            <Anchor href={`/subscriptions?edit=${item?.id}`}>
                                {firstToUpper(item?.name)}
                            </Anchor>
                            .
                        </p>
                    </S.DetailsIntro>
                )}
                <AgreementToInvoice
                    agreements={agreements}
                    networks={networks}
                    tokens={tokens}
                    itemSelected={item?.id}
                    value={agreement}
                    setValue={setAgreement}
                    disabled={isFormWorking}
                />
            </Details>

            {/* 3. PAYMENT DETAILS */}
            <Details
                summary={summarySchedule}
                open={panelsOpen[Panels.SCHEDULE].open}
                disabled={panelsOpen[Panels.SCHEDULE].disabled}
                onToggle={(event: any) =>
                    setPanelOpen(Panels.SCHEDULE, event.target.open)
                }
            >
                <S.DetailsLayout>
                    <ReceivingWallet
                        ref={toAddressRef}
                        value={toWallet}
                        setValue={setToWallet}
                        disabled={isFormWorking}
                        readOnly={!!(toWallet && item?.autoInvoice)}
                    />
                    <S.Amount>
                        <InvoiceAmount
                            ref={amountRef}
                            token={token}
                            usd={usd}
                            value={amount}
                            setValue={setAmount}
                            disabled={isFormWorking}
                        />
                        <AmountInUsd
                            value={usd}
                            setValue={setUsd}
                            disabled={isFormWorking}
                        />
                    </S.Amount>
                    <S.Tags
                        ref={tagsInputRef}
                        value={tags}
                        setValue={setTags}
                        disabled={isFormWorking}
                    />
                    <S.Notes
                        value={notes}
                        setValue={setNotes}
                        disabled={isFormWorking}
                    />
                    <S.BillDetailsSC
                        ref={billDetailsRef}
                        invoiceIds={invoiceIds}
                        setInvoiceIds={setInvoiceIds}
                        value={billDates}
                        setValue={setBillDates}
                        disabled={isFormWorking}
                    />

                    {delegatedSigning ? (
                        <S.SubmitButton
                            onClick={handleSubmit}
                            disabled={isFormWorking}
                        >
                            Submit
                        </S.SubmitButton>
                    ) : contract?.certifiedSigner ? (
                        <S.List>
                            <S.ListItem
                                data-complete={
                                    isCertifiedSigner ? "true" : undefined
                                }
                            >
                                {isCertifiedSigner ? (
                                    <div>
                                        <p>Signer wallet connected</p>
                                        <small>
                                            Signer wallet:{" "}
                                            {contract.certifiedSigner}
                                        </small>
                                    </div>
                                ) : (
                                    <div>
                                        <p>Connect signer wallet</p>
                                        <small>
                                            Signer wallet:{" "}
                                            {contract.certifiedSigner}
                                        </small>
                                        <WalletConnect />
                                    </div>
                                )}
                            </S.ListItem>
                            <S.ListItem
                                data-complete={isAllSigned ? "true" : undefined}
                            >
                                <div>
                                    <p>
                                        Sign {billDates.length} transaction
                                        {billDates.length === 1 ? `` : `s`} with
                                        the signer wallet
                                    </p>
                                    <small>
                                        Tired of signing every transaction? You
                                        can delegate this to Loop. Ping us at{" "}
                                        <Anchor
                                            href={`mailto:${
                                                import.meta.env
                                                    .VITE_EMAIL_SUPPORT
                                            }`}
                                        >
                                            {import.meta.env.VITE_EMAIL_SUPPORT}
                                        </Anchor>{" "}
                                        to find out more.
                                    </small>
                                    <S.SubmitButton
                                        onClick={handleSubmit}
                                        disabled={
                                            isFormWorking || !isCertifiedSigner
                                        }
                                    >
                                        Sign & Submit
                                    </S.SubmitButton>
                                </div>
                            </S.ListItem>
                        </S.List>
                    ) : (
                        <footer>
                            <p>
                                No certified signer wallet is set. Email us at{" "}
                                <Anchor
                                    href={`mailto:${
                                        import.meta.env.VITE_EMAIL_SUPPORT
                                    }`}
                                >
                                    {import.meta.env.VITE_EMAIL_SUPPORT}
                                </Anchor>{" "}
                                to get started.
                            </p>
                        </footer>
                    )}
                </S.DetailsLayout>
            </Details>
        </>
    );
};

const AddNewItems = () => {
    return (
        <p>
            Visit the <Anchor href="/subscriptions">Subscriptions</Anchor> or{" "}
            <Anchor href="/payments">One-time purchase</Anchor> pages to set up
            new items to invoice.
        </p>
    );
};

/* ------------------------------------------------------------------------------------------ */
// [ ] REPLACE ALL THIS WITH DetailsManager and ManagedDetails

interface PanelState {
    open: boolean;
    disabled: boolean;
}

const usePanels = <T extends string>(initialPanels: Record<T, PanelState>) => {
    const [panelsOpen, setPanelsOpen] =
        useState<Record<T, PanelState>>(initialPanels);

    const setPanelOpen = useCallback((panel: T, open: boolean) => {
        setPanelsOpen((prevPanelsOpen) => ({
            ...prevPanelsOpen,
            [panel]: { ...prevPanelsOpen[panel], open },
        }));
    }, []);

    const setPanelDisabled = useCallback((panel: T, disabled: boolean) => {
        setPanelsOpen((prevPanelsOpen) => ({
            ...prevPanelsOpen,
            [panel]: { ...prevPanelsOpen[panel], disabled },
        }));
    }, []);

    return { panelsOpen, setPanelOpen, setPanelDisabled };
};

export default InvoiceForm;

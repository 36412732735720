import { GlobalStyle } from "global-style";
import { useMemo } from "react";
import { Navigate, RouterProvider } from "react-router-dom";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { clarity } from "react-microsoft-clarity";
import { typedCreateBrowserRouter } from "utils/router";
import { NotificationQueueProvider } from "context/NotificationQueue";
import { SessionProvider, LoginType } from "context/Session";
import LoginWeb3 from "components/LoginWeb3";
import ErrorMessage from "components/ErrorMessage";
import Dashboard from "components/Dashboard";
import Question from "components/icons/Question";
import Contact from "components/icons/Contact";
import { MenuData, MenuType } from "components/MainMenu";
import Loading from "components/Loading";
import { ModalProvider } from "context/ModalProvider";
import WalletContextProvider from "context/Wallet";
import { WalletConnectedProvider } from "context/Wallet/WalletConnected";
import Subscription from "customer/components/Subscription";
import QueryProvider from "context/QueryProvider";
import { CustomerProvider, useCustomer } from "customer/context/Customer";
import Merchant from "customer/components/Merchant";
import Home from "customer/components/Home";

if (!clarity.hasStarted()) clarity.init("nk4e5a8fli");

const menus: MenuData[] = [
    {
        id: `resources`,
        heading: `Resources`,
        type: MenuType.Bottom,
        items: [
            {
                path: import.meta.env.VITE_LOOP_DOCS_FAQ || ``,
                icon: (
                    <Question
                        fill="inherit"
                        height="1.25rem"
                        width="1.25rem"
                        name="Frequently asked questions"
                    />
                ),
                label: `FAQs`,
            },
            {
                path: `mailto:${import.meta.env.VITE_EMAIL_HELP}`,
                icon: (
                    <Contact
                        fill="inherit"
                        height="1.25rem"
                        width="1.25rem"
                        name="Contact us"
                    />
                ),
                label: `Contact Us`,
            },
        ],
    },
];

/* RP: LC-657
    This is a temporary solution to theming our application. Similar temporary
    code exists in other files, search "LC-657" to find other instances */
const theme = `light`;

const DashboardWithMenu = () => {
    const { merchantMenuItems } = useCustomer();

    const merchantMenu = useMemo(() => {
        const staticMenus = merchantMenuItems.length
            ? [
                  {
                      id: `merchant-menu`,
                      type: MenuType.Main,
                      heading: `Merchants`,
                      items: merchantMenuItems,
                  },
              ]
            : [];
        return [
            ...staticMenus,
            ...menus.filter(
                (menu) => !staticMenus.map(({ id }) => id).includes(menu.id)
            ),
        ];
    }, [merchantMenuItems]);

    return (
        <Dashboard
            theme={theme}
            menus={merchantMenu}
            wallet={true}
            dataLoaders={[]}
        ></Dashboard>
    );
};

const router = typedCreateBrowserRouter(
    [
        {
            path: "/login",
            element: (
                <WalletContextProvider requiresDynamicLogin={false}>
                    <SessionProvider loginType={LoginType.WEB3}>
                        <LoginWeb3 />
                    </SessionProvider>
                </WalletContextProvider>
            ),
            errorElement: <ErrorMessage>404</ErrorMessage>,
            children: [
                {
                    path: "*",
                    element: <Navigate to="/login" />,
                },
            ],
        },
        {
            path: "/",
            element: (
                <WalletContextProvider>
                    <SessionProvider loginType={LoginType.WEB3}>
                        <WalletConnectedProvider>
                            <ModalProvider>
                                <CustomerProvider>
                                    <DashboardWithMenu />
                                </CustomerProvider>
                            </ModalProvider>
                        </WalletConnectedProvider>
                    </SessionProvider>
                </WalletContextProvider>
            ),
            errorElement: <ErrorMessage>404</ErrorMessage>,
            children: [
                {
                    path: "/",
                    element: <Home />,
                },
                {
                    path: "/:entityId",
                    element: <Merchant />,
                },
                {
                    path: "/:entityId/:agreementId",
                    element: <Subscription />,
                },
                {
                    path: "*",
                    element: <Navigate to="/" />,
                },
            ],
        },
    ],
    {
        future: {
            v7_normalizeFormMethod: true,
        },
    }
);

const CustomerPortal = () => {
    return (
        <>
            <QueryProvider>
                <NotificationQueueProvider>
                    <GlobalStyle />
                    <RouterProvider
                        router={router}
                        fallbackElement={<Loading />}
                    />
                </NotificationQueueProvider>
            </QueryProvider>
            <TawkMessengerReact
                propertyId="66a8d81a32dca6db2cb76cf0"
                widgetId="1i475mnhk"
            />
        </>
    );
};

export default CustomerPortal;

import { useEffect, useState } from "react";
import { CheckoutItemResponse } from "api/types/checkout";
import { BaseItem } from "types/common";
import {
    minimumBalanceRequiredAmountForCheckout,
    calculateMinimumBalance,
} from "utils/checkout";
import { CheckoutItem } from "checkout/types";

export const useMinimumBalance = (
    invoiceAmount: number | undefined,
    items: CheckoutItemResponse[] | BaseItem[] | CheckoutItem[],
    minimumBalanceRequired: number | undefined,
    coupons: CouponResponse[]
) => {
    const [minimumBalance, setMinimumBalance] = useState<number>(0);

    let discountPercentage: number | undefined;
    let discountAmount: number | undefined;

    if (coupons.length > 0) {
        discountPercentage = coupons[0].discountPercentage || undefined;
        discountAmount = coupons[0].discountAmount || undefined;
    }

    useEffect(() => {
        setMinimumBalance(
            minimumBalanceRequiredAmountForCheckout(
                calculateMinimumBalance(
                    invoiceAmount,
                    items,
                    minimumBalanceRequired,
                    { discountPercentage, discountAmount }
                )
            )
        );
    }, [
        discountAmount,
        discountPercentage,
        invoiceAmount,
        items,
        minimumBalanceRequired,
        coupons,
    ]);

    return minimumBalance;
};

import * as S from "./style";
import { useEffect, useState, FunctionComponent } from "react";
import { formatUnits } from "ethers";
import { Company, TransferSource } from "company/types";
import { TransferStatus, ItemCategoryType } from "types/common-enums";
import { toCoin, toDollar } from "utils/financial";
import { BlockExplorerEntityType } from "utils/urls";
import { toNetworkHex } from "utils/addresses";
import { convertTokenToCents } from "utils/exchangeRates";
import UpdateTransferToAddressForm from "company/components/transfers/UpdateTransferToAddressForm";
import ArrowRight from "components/icons/ArrowRight";
import DynamicWalletAddressDisplay from "components/DynamicWalletAddress/DynamicWalletAddressDisplay";
import { useEns } from "context/EnsProvider";
import { useUser, UserRole } from "context/User";
import { useGetCompanyAgreements } from "company/hooks/useGetCompanyAgreements";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import FailedDataFetchingMessage from "components/FailedDataFetchingMessage";
import LoadingBox from "components/LoadingBox";
import { useWallet } from "context/Wallet";
import Title from "components/Title";

interface TransactionSenderAndReceiverProps {
    transaction: Company.Transaction;
}

// It's useful to have a <TransactionSenderAndReceiver> from a usability standpoint
const TransactionSenderAndReceiver: FunctionComponent<TransactionSenderAndReceiverProps> =
    ({ transaction }) => {
        // Session & Company data
        const { hasRole } = useUser();
        const { getTokenBalance, getTokenAllowance } = useWallet();

        const {
            tokens,
            getTokensMetadataIsLoading,
            getTokensMetadataIsError,
            getTokenRate,
        } = useGetTokensMetadata();

        const {
            config: { contracts, entities },
            getCompanyConfigIsLoading,
            getCompanyConfigIsError,
        } = useGetCompanyConfig();

        const { networks, getNetworksIsLoading, getNetworksIsError } =
            useGetNetworks();
        const { items, getCompanyItemsIsLoading, getCompanyItemsIsError } =
            useGetCompanyItems();
        const {
            agreements,
            getCompanyAgreementsIsLoading,
            getCompanyAgreementsIsError,
        } = useGetCompanyAgreements();

        const isLoading =
            getTokensMetadataIsLoading ||
            getCompanyConfigIsLoading ||
            getNetworksIsLoading ||
            getCompanyItemsIsLoading ||
            getCompanyAgreementsIsLoading;

        const isError =
            getTokensMetadataIsError ||
            getCompanyConfigIsError ||
            getNetworksIsError ||
            getCompanyItemsIsError ||
            getCompanyAgreementsIsError;

        const agreement = agreements.find(
            (a) => a.id === transaction?.agreementId
        );
        const token = tokens.find(
            (t) =>
                t.address === agreement?.token &&
                t.networkId === agreement?.networkId
        );

        const network = networks.find(
            (network) => network.id === agreement?.networkId
        );

        const contract = contracts.find(
            (c) => c.networkId === agreement?.networkId
        );

        const item = items.find((item) => agreement?.items.includes(item.id));

        const entity = entities.find(
            (entity) => entity.entityId === agreement?.entity
        );

        const [balance, setBalance] = useState<number | null>(null);
        const [allowance, setAllowance] = useState<string | null>(null);

        const hasRequiredData =
            agreement !== undefined &&
            contract !== undefined &&
            token !== undefined &&
            network !== undefined &&
            entity !== undefined &&
            item !== undefined;

        // Data & hooks
        const canManage = hasRole(UserRole.COMPANY);

        const [showToAddressForm, setShowToAddressForm] =
            useState<boolean>(false);

        // This could be problematic if network number `network.hexId` is unavailable
        const { getEnsRecord } = useEns();

        useEffect(() => {
            if (
                !token?.address ||
                !agreement?.sender.wallet ||
                !token?.networkId ||
                !contract?.address ||
                !token?.decimals
            )
                return;

            getTokenBalance({
                tokenAddress: token.address,
                walletAddress: agreement.sender.wallet,
                networkId: toNetworkHex(token.networkId),
            }).then((balance: string) => {
                setBalance(Number(balance));
            });

            getTokenAllowance({
                tokenAddress: token.address,
                contractAddress: contract.address,
                walletAddress: agreement.sender.wallet,
                networkId: toNetworkHex(token.networkId),
            }).then((allowance) => {
                setAllowance(formatUnits(allowance, token.decimals));
            });
        }, [
            token?.address,
            agreement?.sender.wallet,
            token?.networkId,
            contract?.address,
            token?.decimals,
            getTokenBalance,
            getTokenAllowance,
        ]);

        if (isLoading) {
            return <LoadingBox height="20rem" />;
        }

        if (isError) {
            return <FailedDataFetchingMessage />;
        }

        if (!hasRequiredData) {
            return <FailedDataFetchingMessage />;
        }

        const rate = getTokenRate(token)?.rate;
        const allowanceInUsd =
            rate &&
            allowance !== null &&
            toDollar(convertTokenToCents(Number(allowance), rate));

        const balanceInUsd =
            rate &&
            balance !== null &&
            toDollar(convertTokenToCents(balance, rate));

        const isLoopTransfer =
            transaction.sourceId &&
            [TransferSource.Manual, TransferSource.AutoGenerated].includes(
                transaction.sourceId
            );

        const isScheduledOrDraftTransfer = [
            TransferStatus.Scheduled,
            TransferStatus.Draft,
        ].includes(transaction.status as TransferStatus);

        const outboundPayment = item.type === ItemCategoryType.OutboundPayment;
        const canEditToAddress =
            canManage &&
            isLoopTransfer &&
            entity.delegatedSigning &&
            isScheduledOrDraftTransfer &&
            outboundPayment;

        return (
            <S.TransactionSenderAndReceiver>
                <S.WalletContainer>
                    <S.Header level="h3" size="h4" bold={false}>
                        Sender
                    </S.Header>
                    <S.WalletDetails>
                        <strong>
                            <DynamicWalletAddressDisplay
                                address={agreement.sender.wallet}
                                ensName={
                                    getEnsRecord(agreement.sender.wallet)?.name
                                }
                                networkId={network?.hexId}
                                shorten
                                type={BlockExplorerEntityType.Address}
                                icon
                                iconFill="currentColor"
                            />
                        </strong>
                        {agreement.sender.email && (
                            <div>{agreement.sender.email}</div>
                        )}
                        <S.AllowanceAndBalanceContainer>
                            <S.Row>
                                <Title level="h4" wrap={false}>
                                    Allowance
                                </Title>
                                <div>
                                    {allowance === null ? (
                                        <S.Loading desaturate />
                                    ) : (
                                        <>
                                            <S.AmountInToken>
                                                {toCoin(allowance)}{" "}
                                                {token.symbol}
                                            </S.AmountInToken>
                                            {allowanceInUsd && (
                                                <S.AmountInUsd>
                                                    ({allowanceInUsd})
                                                </S.AmountInUsd>
                                            )}
                                        </>
                                    )}
                                </div>
                            </S.Row>
                            <S.Row>
                                <Title level="h4" wrap={false}>
                                    Balance
                                </Title>
                                <div>
                                    {balance === null ? (
                                        <S.Loading desaturate />
                                    ) : (
                                        <>
                                            <S.AmountInToken>
                                                {toCoin(balance)} {token.symbol}
                                            </S.AmountInToken>
                                            {balanceInUsd && (
                                                <S.AmountInUsd>
                                                    ({balanceInUsd})
                                                </S.AmountInUsd>
                                            )}
                                        </>
                                    )}
                                </div>
                            </S.Row>
                        </S.AllowanceAndBalanceContainer>
                    </S.WalletDetails>
                </S.WalletContainer>
                <S.ArrowContainer>
                    <ArrowRight fill="#47464F" />
                </S.ArrowContainer>
                <S.WalletContainer>
                    <S.Header level="h3" size="h4" bold={false}>
                        Receiver
                    </S.Header>
                    {showToAddressForm ? (
                        <UpdateTransferToAddressForm
                            transaction={transaction}
                            onSuccess={() => setShowToAddressForm(false)}
                            onCancel={() => setShowToAddressForm(false)}
                        />
                    ) : (
                        <S.WalletDetails>
                            <strong>
                                <DynamicWalletAddressDisplay
                                    address={transaction.receiver.wallet}
                                    ensName={
                                        getEnsRecord(
                                            transaction.receiver.wallet
                                        )?.name
                                    }
                                    networkId={network?.hexId}
                                    type={BlockExplorerEntityType.Address}
                                    icon
                                    iconFill="currentColor"
                                    shorten
                                />
                                {canEditToAddress && (
                                    <S.Edit
                                        href="#"
                                        onClick={() =>
                                            setShowToAddressForm(true)
                                        }
                                    >
                                        Edit receiver
                                    </S.Edit>
                                )}
                            </strong>
                            {transaction.receiver.email && (
                                <div>{transaction.receiver.email}</div>
                            )}
                        </S.WalletDetails>
                    )}
                </S.WalletContainer>
            </S.TransactionSenderAndReceiver>
        );
    };

export default TransactionSenderAndReceiver;

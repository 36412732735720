import { sizes as s } from "global-style";
import styled, { css } from "styled-components";
import colors from "theme/colors";
import spacing from "theme/spacing";
import { itemSpacing } from "customer/App/style";
import Badge from "components/Badge";

export const SubscriptionHeader = styled.div`
    display: grid;
    column-gap: ${spacing.md};
    align-items: start;
    @media (min-width: ${s.sm}) {
        grid-template-columns: 1fr auto;
    }
`;

export const Row = css`
    @media (min-width: ${s.sm}) {
        grid-template-columns: 10rem auto;
    }
    gap: 1rem;
    dt {
        color: ${colors.neutralDark};
    }
    dd {
        justify-self: end;
        @media (min-width: ${s.sm}) {
            justify-self: start;
        }
    }
`;

export const HistoryTable = styled.ul`
    display: grid;
    gap: ${spacing[itemSpacing]};
    grid-template-columns: auto;
    @media (min-width: ${s.sm}) {
        grid-template-columns:
            minmax(clamp(8rem, 20%, 10rem), auto) minmax(
                clamp(16rem, 35%, 20rem),
                auto
            )
            minmax(15%, auto)
            1fr;
    }
`;

export const ListRow = styled.li`
    display: grid;
    column-gap: 1rem;
    align-items: center;
    grid-column: 1 / -1;
    grid-template-columns: inherit;
    @supports (grid-template-columns: subgrid) {
        grid-template-columns: subgrid;
    }
`;

export const LowWarning = styled(Badge)`
    margin-inline-start: ${spacing.sm};
`;
